import * as grid from "../styles/grid.module.scss";
import ContentMargins from "../components/layout/ContentMargins";
import Divider from "../components/divider/Divider";
import Layout from "../components/layout/layout";
import PageTitle from "../components/contentHeadings/PageTitle";
import React from 'react';
import Seo from "../components/Seo";
import SubHeading from "../components/contentHeadings/SubHeading";
import TertiaryHeading from "../components/contentHeadings/TertiaryHeading";

const HvorErViPage = () => {
    return(
        <Layout>
            <ContentMargins topMargin>
                <Seo title={'Hvor er vi?'}/>
                <section>
                    <div className={grid.container}>
                        <div className={grid.xs12Sm6P8}>
                            <PageTitle>Hvor finner du oss?</PageTitle>
                            <p>Vi har to salonger i Oslo.</p>
                            <p>På Grünerløkka (<a href={'#grunerlokka'}>Hvordan komme seg hit?</a>)</p>
                            <p>I Oslo sentrum (<a href={'#sentrum'}>Hvordan komme seg hit?</a>)</p>
                            <p>Når du bestiller time i vårt online bookingprogram kan du velge hvilken salong du vil dra til, og du vil få tydelig beskjed på sms hvilken salong du har time hos. I meldingen er det også en link til Google Maps. </p>
                        </div>
                        <div className={grid.xs12Sm6P8}>
                            <div style={{width: "100%", paddingBottom: "50%", position: "relative"}}>
                                <img
                                    src={"https://maps.googleapis.com/maps/api/staticmap?autoscale=2&size=600x300&maptype=roadmap&key=AIzaSyDmAHRDXnD7dIdb36a9Jg11U23tdo8IJdk&format=png&visual_refresh=true&markers=size:mid%7Ccolor:0xff0000%7Clabel:1%7CBenedikte+Vippeextensions+Grünerløkka&markers=size:mid%7Ccolor:0xff0000%7Clabel:2%7CBenedikte+Vippeextensions+Sentrum"}
                                    alt={"Benedikte Vippeextensions Kart"}
                                    style={{position: "absolute", left: 0, right: 0, top: 0, bottom: 0}}
                                />
                            </div>
                            <p>Klikk <a href={"https://goo.gl/maps/jMDzViesqxB2"} target={"_blank"} rel="noopener noreferrer">her</a> for å se salongen i sentrum på Google Maps.</p>
                            <p>Klikk <a href={"https://goo.gl/maps/vRaHZhUYq9q"} target={"_blank"} rel="noopener noreferrer">her</a> for å se salongen på Grünerløkka på Google Maps.</p>
                        </div>
                    </div>
                </section>
                <Divider color={"pink"}/>
                <div className={grid.container}>
                    <div className={grid.xs12Sm6P8} id={"grunerlokka"}>
                        <section>
                            <SubHeading>Hvordan komme seg hit? - Grünerløkka</SubHeading>
                            <p>De nærmeste trikk- og busstoppene er Schous Plass, Heimdalsgata og Jakob Kirke. Vi ligger kort gangavstand fra alle tre.</p>
                            <p>Man kan ta 11-, 12- eller 13-trikken til Schous Plass.</p>
                            <p>Man kan ta 17-trikken, 30-, eller 31-bussen til Heimdalsgata.</p>
                            <p>Man kan ta 54- eller 34-bussen til Jakob Kirke/Calmeyers Gate (Stoppet har forskjellig navn på hver side av veien).</p>
                            <TertiaryHeading>Parkering</TertiaryHeading>
                            <p>Salongen har ikke egen parkering, men det er mulig å parkere langs veien rundt i området mot en avgift.</p>
                        </section>
                    </div>
                    <div className={grid.xs12Sm6P8} id={"sentrum"}>
                        <section>
                            <SubHeading>Hvordan komme seg hit? - Sentrum</SubHeading>
                            <p>Det tar 7 minutter å gå til salongen fra Jernbanetorget. Gå oppover Karl Johan, og ta til venstre ved Bik Bok. Klikk her for å se ruten på Google Maps.</p>
                            <p>Det nærmeste trikkestoppet er Dronningens Gate og Øvre Slottsgate. Det nærmeste busstoppet er Kvadraturen, men her stopper bussene kun i den éne retningen.</p>
                            <p>Man kan ta 30-, 31-, 32-, 54-, 70-, og 81-bussene til Kvadraturen dersom man kommer fra vest (f.eks. fra Nationaltheateret).</p>
                            <p>Man kan ta 12-, 13-, og 19-trikken til Dronningens gate og Øvre Slottsgate. Derfra er det 1 min å gå til salongen.</p>
                            <TertiaryHeading>Parkering</TertiaryHeading>
                            <p>Salongen har ikke egen parkering, men Bankplassen P-Hus ligger rett i nærheten. Det tar tre minutter å gå fra parkeringshuset til salongen. <a href={"https://goo.gl/maps/ez6d8LHP7RL2"} target={"_blank"} rel="noopener noreferrer">Klikk her for å se kart</a>.</p>
                        </section>
                    </div>
                </div>
            </ContentMargins>
        </Layout>
    )
};

export default HvorErViPage;