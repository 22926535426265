import PropTypes from 'prop-types';
import React from 'react';
import { image, wrapper, flipImage } from './divider.module.scss';
import {StaticImage} from "gatsby-plugin-image";
import outputPixelDensities from "../../data/defaultPixelDensities";

const Divider = ({color, flip}) => {
    const images = {
        pink: <StaticImage
            src={"../../images/illustrations/divider/divider-pink.png"}
            alt={""}
            width={350}
            className={image}
            outputPixelDensities={outputPixelDensities}
        />,
        blue: <StaticImage
            src={"../../images/illustrations/divider/divider-blue.png"}
            alt={""}
            width={350}
            className={image}
            outputPixelDensities={outputPixelDensities}
        />,
        gold: <StaticImage
            src={"../../images/illustrations/divider/divider-gold.png"}
            alt={""}
            width={350}
            className={image}
            outputPixelDensities={outputPixelDensities}
        />,
        white: <StaticImage
            src={"../../images/illustrations/divider/divider-white.png"}
            alt={""}
            width={350}
            className={image}
            outputPixelDensities={outputPixelDensities}
        />
    };

    return(
        <div className={wrapper + (!!flip ? ' ' + flipImage : '')}>
            { images[color] }
        </div>
    )
};

Divider.propTypes = {
    color: PropTypes.oneOf(['pink', 'blue', 'white', 'gold']).isRequired,
    flip: PropTypes.bool
};

export default Divider;